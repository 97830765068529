import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { LogIn } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import Form from "../../Components/Form";
import Loader from "../../Components/Loader";
import { ILoginCredentials } from "../../Interfaces";
import { UserContext } from "../../Sources/UserSource";
import { Role } from "../../types/globalTypes";

const Login = () => {
  const history = useHistory<{ location?: Location }>();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const doRedirect = () => {
    if (!userContext?.user?.role) {
        return;
    }
    switch (userContext.user!.role) {
      case Role.Client:
        history.replace("/videos");
        break;
      case Role.Realtor:
      case Role.OrgAdmin:
        history.replace("/");
        break;
      case Role.SysOp:
        history.replace("/manage/organizations");
        break;
    }
  };

  const handleSubmit = async (form: ILoginCredentials) => {
    setLoading(true);
    await userContext
      .logIn(form)
      .then(() => {
        doRedirect();
      })
      .catch((err) => {
        if (err.response?.status !== 422) {
          // Unprocessable Entity
          console.error(err.stack);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userContext.loggedIn) doRedirect();

    // We don't want to end up with some nasty super duper infinite rerenders so we'll do this.
    // Better approach would be to just simply memoize it but /shrug
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.loggedIn, userContext.user]);

  return (
    <Container fluid>
      <Row className="py-4 px-2">
        <h1>Log in</h1>
      </Row>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          email: "",
          password: "",
          remember: true,
        }}
        validationSchema={yup.object().shape({
          email: yup.string().required().email().label("Email"),
          password: yup.string().required().label("Password"),
        })}
      >
        <Form errors={userContext.loginErrors}>
          <Form.Row>
            <Form.Field type="email" placeholder="Email" name="email" column />
          </Form.Row>
          <Form.Row>
            <Form.Field
              type="password"
              placeholder="Password"
              name="password"
              column
            />
          </Form.Row>
          <Form.Row>
            <Form.Field
              type="checkbox"
              name="remember"
              label="Remember my login"
              column
            />
          </Form.Row>

          <Loader loaded={!loading} />
          <Button type="submit" variant="primary" disabled={loading}>
            Log in
            {` `}
            <LogIn />
          </Button>
          <Link to="/forgot" className="p-3">
            Forgot your password?
          </Link>
        </Form>
      </Formik>
    </Container>
  );
};

export default Login;
